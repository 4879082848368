import BaseModel from "@/models/base-model.js";

export default class Size extends BaseModel {
  fields = {
    name: {
      type: "text",
      title: "Nama",
      label: "nama",
      minLen: 1,
      validationRules: {
        required: true,
        minLength: true,
      },
    },
    description: {
      type: "textarea",
      title: "Deskripsi",
      label: "description",
    },
    sort: {
      type: "number",
      title: "Urutan",
      label: "sort",
    },
    is_active: {
      label: "is_active",
      title: "Status",
      type: "radio",
      useInteger: true,
      value: 1,
      options: {
        1: "Aktif",
        0: "Tidak Aktif",
      },
      validationRules: {
        required: true,
      },
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "sizes";
}
