<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import size from "@/models/size.js";
import detailGroupInput from "@/components/detail-group-input.vue";
import Loading from "@/components/skeleton.vue";

const sizeModel = new size();

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, detailGroupInput, Loading },
  page: {
    title: "Detail Ukuran",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Detail Ukuran",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produk",
        },
        {
          text: "Ukuran",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      isLoading: false,
      setData: [],
      columns: [
        {
          value: "name",
          label: "Nama",
        },
        {
          value: "description",
          label: "Deskripsi",
        },
        {
          value: "sort",
          label: "Urutkan",
          useBoolean: ["Ya", "Tidak"],
        },
        {
          value: "is_active",
          label: "Status",
          useBoolean: ["Aktif", "Tidak Aktif"],
        },
        {
          value: "updated_at",
          label: "Tgl di Perbarui",
        },
        {
          value: "created_at",
          label: "Tgl di Buat",
        },
      ],
      rowCount: 6,
    };
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.isLoading = true;
      sizeModel
        .find(this.$route.params.id)
        .then((resp) => {
          this.setData = resp;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 404) {
            this.$router.push("/404-notfound");
          }
          this.isLoading = false;
        });
    },
    returnBack() {
      return this.$router.go(-1);
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <b-row v-if="!isLoading">
          <detail-group-input :columns="columns" :data="setData" />
          <router-link to="/produk/ukuran">
            <b-btn
              variant="success"
              class="ml-auto text-white m-3"
              style="
              width: fit-content;
              display: flex;
              justify-content: center;
              align-items: center;
            "
              ><i class="uil-arrow-left"></i> Kembali
            </b-btn>
          </router-link>
        </b-row>
        <b-row v-else>
          <b-col class="my-3" sm="6" md="4" v-for="row in rowCount" :key="row">
            <loading
              class="my-2"
              width="50%"
              height="2rem"
              borderRadius="7px"
            />
            <loading width="100%" height="3rem" borderRadius="7px" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </Layout>
</template>
